import '../scss/main.scss';
import '../scss/index.scss';

import Vue from './_vue-signleton';
import './_common.js';
import Helpers from './util/helpers.js';

const PROMOTED_NUMBER = 3;

Vue.setOptions({
  created: function() {
    Helpers.shuffleCards('.work .card', PROMOTED_NUMBER);
  }
});
